#apartment_selection__3d {
    width: 100%;
    height: 100%;
    transition: all .3s ease-in-out;
    .step_content {
        height: 100%;
        padding: 0 !important; } }
#root3dEstate {
    width: 100%;
    height: 100%; }

@media (max-width: 1100px) {
    #root3dEstate {
        display: none !important; } }

.show3DView_hidden {
    opacity: 0 !important;
    width: 0 !important;
    height: 0 !important;
    z-index: -9999999 !important; }


._3desm__app {
    z-index: 999999 !important; }
._3desm__root__mobile {
    top: 74px !important;
    padding-top: unset !important; }
._3desm__root__mobile._3desm__mobile {
    background-color: unset !important; }
._3desm__mobile-header {
    display: none !important; }
